import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StoryTeamMain,
  StoryTeamImage,
  StoryTeamHeading,
  StoryTeamImageFooter,
} from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import RichText from '../../RichText'
import { Heading2 } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { colors } from '../../../styles/vars/colors.style'
import Slider from 'react-slick'
import CarouselDots from '../../CarouselDots'

const StoryTeam = ({ title, text, images }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const sliderSettings = {
    className: 'slick-slider--dark',
    autoplay: true,
    beforeChange: (oldIndex, newIndex) => {
      setActiveIndex(newIndex)
    },
  }

  return (
    <ScrollSection>
      <StoryTeamMain>
        <Container>
          <Grid>
            <GridItem tabletL={6} desk={7} tinyAlign={'center'}>
              <AnimateSlideIn>
                <StoryTeamImage>
                  <Slider {...sliderSettings}>
                    {React.Children.toArray(
                      images.map(image => (
                        <GatsbyImage
                          image={image.gatsbyImageData}
                          alt={image.description}
                        />
                      ))
                    )}
                  </Slider>

                  <StoryTeamImageFooter>
                    {images.length > 1 && (
                      <CarouselDots
                        slides={images}
                        activeIndex={activeIndex}
                        theme="dark"
                      />
                    )}
                  </StoryTeamImageFooter>
                </StoryTeamImage>
              </AnimateSlideIn>
            </GridItem>

            <GridItem
              tabletL={5}
              tabletLStart={8}
              desk={3}
              deskStart={9}
              tinyAlign={'center'}
            >
              <StoryTeamHeading>
                <Heading2 color={colors.blue}>
                  <AnimateSplitText>{title}</AnimateSplitText>
                </Heading2>
              </StoryTeamHeading>

              <Spacer size={32} />

              <RichText content={text} paragraphSize="regular" />
            </GridItem>
          </Grid>

          <Spacer size={[100, 210]} />
        </Container>
      </StoryTeamMain>
    </ScrollSection>
  )
}

StoryTeam.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
}

export default StoryTeam
