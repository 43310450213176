import styled from 'styled-components'

export const StoryStoryMain = styled.div`
  position: relative;
`

export const StoryStoryBorder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0.1rem;
  background-color: rgba(142, 142, 148, 0.5);
`
