import React, { useRef, useState, useContext } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import {
  StoryJourneyInner,
  StoryJourneyCarousel,
  StoryJourneyCarouselImages,
  StoryJourneyCarouselFooter,
  StoryJourneyCarouselDots,
  StoryJourneyCarouselText,
} from './index.style'
import Slider from 'react-slick'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import CarouselDots from '../../CarouselDots'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import RichText from '../../RichText'
import ScrollSection from '../../ScrollSection'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StoreDispatch } from '../../../Store'
import { colors } from '../../../styles/vars/colors.style'

const StoryJourney = ({ title, text, slides }) => {
  const dispatch = useContext(StoreDispatch)
  const [activeIndex, setActiveIndex] = useState(0)
  const textRefs = useRef([])
  const textAnimateDuration = 0.3

  const sliderSettings = {
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      const prevText = textRefs.current[oldIndex]

      gsap.set(prevText, {
        yPercent: 100,
        opacity: 0,
        onComplete: () => {
          gsap.delayedCall(textAnimateDuration, () => {
            setActiveIndex(newIndex)

            gsap.to(prevText, {
              clearProps: 'transform,opacity',
            })
          })
        },
      })
    },
  }

  return (
    <ScrollSection>
      <Container>
        <StoryJourneyInner>
          <Grid>
            <GridItem
              tabletL={5}
              tabletLStart={2}
              desk={4}
              deskStart={2}
              tinyAlign={'center'}
            >
              <RichText content={title} color={colors.blue} heading1 />

              <Spacer size={32} />

              <RichText content={text} paragraphSize="regular" />

              <Spacer size={16} />
            </GridItem>

            <GridItem tabletL={4} tabletLStart={8} tinyAlign="center">
              <StoryJourneyCarousel>
                <Slider {...sliderSettings}>
                  {React.Children.toArray(
                    slides.map(slide => (
                      <StoryJourneyCarouselImages
                        onMouseEnter={() => dispatch({ type: 'SHOW_CURSOR' })}
                        onMouseOut={() => dispatch({ type: 'HIDE_CURSOR' })}
                      >
                        <AnimateSlideIn>
                          <GatsbyImage
                            image={slide.image.gatsbyImageData}
                            alt={slide.image.description}
                          />
                        </AnimateSlideIn>
                      </StoryJourneyCarouselImages>
                    ))
                  )}
                </Slider>
              </StoryJourneyCarousel>

              <StoryJourneyCarouselFooter>
                {React.Children.toArray(
                  slides.map((slide, slideIndex) => (
                    <StoryJourneyCarouselText
                      ref={ref => (textRefs.current[slideIndex] = ref)}
                      active={slideIndex === activeIndex}
                      duration={textAnimateDuration}
                    >
                      <RichText content={slide.text} />
                    </StoryJourneyCarouselText>
                  ))
                )}
              </StoryJourneyCarouselFooter>

              <StoryJourneyCarouselDots>
                <AnimateSlideIn>
                  <CarouselDots
                    activeIndex={activeIndex}
                    slides={slides}
                    theme="dark"
                  />
                </AnimateSlideIn>
              </StoryJourneyCarouselDots>
            </GridItem>
          </Grid>
        </StoryJourneyInner>
        <Spacer size={[100, 250]} />
      </Container>
    </ScrollSection>
  )
}

StoryJourney.propTypes = {
  title: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
  slides: PropTypes.array.isRequired,
}

export default StoryJourney
