import React from 'react'
import PropTypes from 'prop-types'
import {
  StoryHeroMain,
  StoryHeroText,
  StoryHeroSmallImageWrapper,
  StoryHeroSmallImage,
  StoryHeroLargeImageWrapper,
  StoryHeroLargeImage,
} from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import RichText from '../../RichText'
import { Heading1 } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSplitText from '../../animation/AnimateSplitText'

const StoryHero = ({ title, text, large, small }) => (
  <ScrollSection>
    <StoryHeroMain>
      <Container>
        <Spacer size={[0, 165]} />

        <Grid>
          <GridItem
            tabletL={6}
            tabletLStart={1}
            desk={5}
            deskStart={2}
            deskAlign={'center'}
          >
            <Spacer size={116} />

            <Heading1 as="h2">
              <AnimateSplitText heading gradient>
                {title}
              </AnimateSplitText>
            </Heading1>

            <Spacer size={32} />

            <StoryHeroText>
              <RichText content={text} paragraphSize="regular" delay={0.3} />
            </StoryHeroText>
          </GridItem>

          <GridItem mobile={5} tabletL={2} tinyAlign={'flex-end'}>
            <StoryHeroSmallImageWrapper
              data-scroll
              data-scroll-position="top"
              data-scroll-speed="4"
              data-scroll-delay="0.05"
            >
              <AnimateSlideIn>
                <StoryHeroSmallImage>
                  <GatsbyImage
                    image={small.gatsbyImageData}
                    alt={small.description}
                  />
                </StoryHeroSmallImage>
              </AnimateSlideIn>
            </StoryHeroSmallImageWrapper>
          </GridItem>

          <GridItem mobile={7} tabletL={4}>
            <StoryHeroLargeImageWrapper
              data-scroll
              data-scroll-position="top"
              data-scroll-speed="6"
              data-scroll-delay="0.05"
            >
              <AnimateSlideIn>
                <StoryHeroLargeImage>
                  <GatsbyImage
                    image={large.gatsbyImageData}
                    alt={large.description}
                  />
                </StoryHeroLargeImage>
              </AnimateSlideIn>
            </StoryHeroLargeImageWrapper>
            <Spacer size={[69, 128]} />
          </GridItem>
        </Grid>

        <Spacer size={[64, 90]} />
      </Container>
    </StoryHeroMain>
  </ScrollSection>
)

StoryHero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  large: PropTypes.object.isRequired,
  small: PropTypes.object.isRequired,
}

export default StoryHero
