import React from 'react'
import PropTypes from 'prop-types'
import { StoryStoryBorder, StoryStoryMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import RichText from '../../RichText'
import { Heading2 } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { colors } from '../../../styles/vars/colors.style'
import { useInView } from 'react-intersection-observer'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const StoryStory = ({ title, text }) => {
  const [ref, inView] = useInView()

  return (
    <ScrollSection>
      <StoryStoryMain ref={ref} inView={inView}>
        <AnimateSlideIn direction="left" delay={0.2}>
          <StoryStoryBorder />
        </AnimateSlideIn>

        <Container>
          <Spacer size={[69, 229]} />

          <Grid>
            <GridItem tabletL={6} desk={4} deskStart={2}>
              <Heading2 color={colors.blue}>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading2>
            </GridItem>

            <GridItem tabletL={6} tabletLStart={7} desk={5} deskStart={7}>
              <RichText content={text} paragraphSize="regular" />
            </GridItem>
          </Grid>

          <Spacer size={[80, 245]} />
        </Container>
      </StoryStoryMain>
    </ScrollSection>
  )
}

StoryStory.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object.isRequired,
}

export default StoryStory
