import { transparentize } from 'polished'
import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { SpacerMain } from '../../Spacer/index.style'

export const StoryTeamMain = styled.div`
  *:not(${SpacerMain}) {
    min-height: 0;
    min-width: 0;
  }
`

export const StoryTeamImage = styled.div`
  position: relative;

  .slick-list {
    position: relative;
    border-radius: 1.6rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  .slick-arrow {
    position: absolute;
    bottom: 0;
    visibility: hidden;
    border-color: ${transparentize(0.5, colors.midGrey)};

    ${mq.tabletL} {
      visibility: visible;
    }
  }

  .slick-next {
    transform: translateX(100%);
    left: 1.5rem;
  }
`

export const StoryTeamImageFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;

  ${mq.tabletL} {
    align-items: flex-end;
    justify-content: flex-end;
  }
`

export const StoryTeamHeading = styled.div`
  ${mq.deskL} {
    max-width: 75%;
  }
`
