import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import StoryHero from '../components/Story/StoryHero'
import StoryStory from '../components/Story/StoryStory'
import StoryTeam from '../components/Story/StoryTeam'
import StoryJourney from '../components/Story/StoryJourney'
import PageNav from '../components/PageNav'

const OurStoryPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    heroTitle,
    heroTextRich,
    heroImageLarge,
    heroImageSmall,
    storyTitle,
    storyTextRich,
    teamImages,
    textTitle,
    teamTextRich,
    journeyTitle1,
    journeyTextRich,
    journeySlides,
    pageNavigation,
  } = data.contentfulOurStory

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.ourStory}
      />
      <StoryHero
        title={heroTitle}
        text={heroTextRich}
        large={heroImageLarge}
        small={heroImageSmall}
      />
      <StoryStory title={storyTitle} text={storyTextRich} />
      <StoryTeam title={textTitle} text={teamTextRich} images={teamImages} />
      <StoryJourney
        title={journeyTitle1}
        text={journeyTextRich}
        slides={journeySlides}
      />
      <PageNav
        title={pageNavigation.title}
        linkSlug={pageNavigation.pageLink.slug}
        linkText={pageNavigation.linkText}
        image={pageNavigation.image}
      />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          ourStory
        }
      }
    }
    contentfulOurStory {
      seoTitle
      seoDescription {
        seoDescription
      }
      heroTitle
      heroTextRich {
        raw
      }
      heroImageLarge {
        gatsbyImageData
        description
      }
      heroImageSmall {
        gatsbyImageData(width: 284, height: 380)
        description
      }
      storyTitle
      storyTextRich {
        raw
      }
      teamImages {
        gatsbyImageData
        description
      }
      textTitle
      teamTextRich {
        raw
      }
      journeyTitle
      journeyTitle1 {
        raw
      }
      journeyTextRich {
        raw
      }
      journeySlides {
        text {
          raw
        }
        image {
          gatsbyImageData
          description
        }
      }
      pageNavigation {
        ...PageLink
      }
    }
  }
`

export default OurStoryPage
