import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { mq } from '../../../styles/vars/media-queries.style'

export const StoryHeroMain = styled.div``

export const StoryHeroText = styled.div`
  ${mq.tabletL} {
    padding-bottom: 11.6rem;
  }
  ${mq.desk} {
    ${clamp('padding-bottom', 232, 232, breakpoints.desk, breakpoints.deskL)}
  }
  ${mq.deskL} {
    max-width: 70%;
  }
`

export const StoryHeroSmallImageWrapper = styled.div`
  position: relative;
  z-index: -1;
`

export const StoryHeroSmallImage = styled.div`
  border-radius: 1.6rem;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export const StoryHeroLargeImageWrapper = styled.div`
  position: relative;
  z-index: -1;

  ${mq.tabletLMax} {
    margin-top: 1.6rem;
  }
`

export const StoryHeroLargeImage = styled.div`
  border-radius: 1.6rem;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`
