import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { easings } from '../../../styles/vars/easings.style'
import { AnimateSlideInMask } from '../../animation/AnimateSlideIn/index.style'
import { SpacerMain } from '../../Spacer/index.style'
import { RichTextMain } from '../../RichText/index.style'

export const StoryJourneyInner = styled.div`
  ${mq.tabletL} {
    background-color: ${colors.light};
    border-radius: 1.6rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    ${clamp('padding-top', 80, 180, breakpoints.tabletL, breakpoints.deskL)}
    ${clamp('padding-bottom', 80, 180, breakpoints.tabletL, breakpoints.deskL)}
  }

  ${RichTextMain} {
    h2 {
      ${mq.tabletL} {
        ${clamp('font-size', 40, 50, breakpoints.tabletL, breakpoints.desk)};
      }

      ${mq.desk} {
        ${clamp('font-size', 50, 74, breakpoints.desk)};
      }
    }
  }

  *:not(${SpacerMain}) {
    min-height: 0;
    min-width: 0;
  }
`

export const StoryJourneyCarousel = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  border-radius: 1.6rem 1.6rem 0 0;

  :hover {
    cursor: none;
  }
`

export const StoryJourneyCarouselImages = styled.div`
  padding: 4rem;

  :focus {
    outline: none;
  }

  ${AnimateSlideInMask} {
    pointer-events: none;
  }
`

export const StoryJourneyCarouselFooter = styled.div`
  padding-bottom: 3.6rem;
  text-align: center;
  background-color: ${colors.light};
  border-radius: 0 0 1.6rem 1.6rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${mq.tabletL} {
    padding-bottom: 2.4rem;
  }
`

export const StoryJourneyCarouselText = styled.div`
  position: ${props => (props.active ? 'relative' : 'absolute')};
  transform: translateY(${props => (props.active ? '0' : '100%')});
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity ${props => props.duration}s ${easings.inOut.default},
    transform ${props => props.duration}s ${easings.inOut.default};
`

export const StoryJourneyCarouselDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${clamp('padding-top', 20, 24)}

  ${mq.tabletL} {
    padding-top: 0;
  }
`
